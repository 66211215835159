exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-book-a-demo-tsx": () => import("./../../../src/pages/book-a-demo.tsx" /* webpackChunkName: "component---src-pages-book-a-demo-tsx" */),
  "component---src-pages-contact-pricing-tsx": () => import("./../../../src/pages/contact-pricing.tsx" /* webpackChunkName: "component---src-pages-contact-pricing-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-end-user-terms-conditions-tsx": () => import("./../../../src/pages/end-user-terms-conditions.tsx" /* webpackChunkName: "component---src-pages-end-user-terms-conditions-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learning-tsx": () => import("./../../../src/pages/learning.tsx" /* webpackChunkName: "component---src-pages-learning-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-professional-services-tsx": () => import("./../../../src/pages/professional-services.tsx" /* webpackChunkName: "component---src-pages-professional-services-tsx" */),
  "component---src-pages-software-tsx": () => import("./../../../src/pages/software.tsx" /* webpackChunkName: "component---src-pages-software-tsx" */),
  "component---src-pages-trial-tsx": () => import("./../../../src/pages/trial.tsx" /* webpackChunkName: "component---src-pages-trial-tsx" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

